import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="slogan"
export default class extends Controller {
	static targets = ["text", "spinner"]

  connect() {
		let startText = ["Irgendwo ist es 4:20.", "Enjoy your Trip", "Puff Puff Pass"]
		let rand = startText[Math.floor(Math.random() * startText.length)];
		setTimeout(() => {
			this.spinnerTarget.classList.add("hidden");
			this.textTarget.innerHTML = rand;
		}, 1500);

  }
	generate(){
		let texts = ["Irgendwo ist es 4:20.", "Enjoy your Trip", "Puff Puff Pass"]
		let random = texts[Math.floor(Math.random() * texts.length)];
		this.textTarget.classList.add("hidden");
		this.spinnerTarget.classList.remove("hidden");
		setTimeout(() => {
			this.textTarget.classList.remove("hidden");
			this.spinnerTarget.classList.add("hidden");
			this.textTarget.innerHTML = random;
		}, 1500);
	}
}
